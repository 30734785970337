class List {
  constructor(container) {
    this.container = container;
    this.activeIndex = -1;
    this.indexCount = parseInt(
      container.getAttribute('data-list-index-count'),
      10,
    );
    this.init();
  }
  init = () => {
    this.initDoms();

    window.addEventListener('resize', this.resize);
    this.resize();

    this.doms.listItems.forEach((item, index) => {
      item.addEventListener('mouseenter', () => {
        this.handleItemMouseEnter(index);
      });
      item.addEventListener('mouseleave', () => {
        this.handleItemMouseLeave(index);
      });
    });
  };

  initDoms = () => {
    this.doms = {
      listItems: this.container.querySelectorAll('[data-list-item]'),
      listWrapper: this.container.querySelector('[data-list-wrapper]'),
      listHovers: this.container.querySelectorAll('[data-list-hover]'),
      listHoverWrapper: this.container.querySelector(
        '[data-list-hover-wrapper]',
      ),
    };
  };

  resize = () => {
    this.setHoverSize();
  };

  setHoverSize = () => {
    let height = 0;
    this.doms.listHovers.forEach((elem) => {
      const elemHeight = elem.clientHeight;
      height = Math.max(elemHeight, height);
    });
    this.doms.listHoverWrapper.style.height = `${height}px`;
    this.doms.listHoverWrapper.style.top = `calc(50% - ${height / 2}px)`;
  };

  handleItemMouseEnter = (index) => {
    this.activeIndex = index;
    this.animateIn();
    this.doms.listWrapper.classList.add('active');
  };

  handleItemMouseLeave = () => {
    this.animateOut();
    this.activeIndex = -1;
    this.doms.listWrapper.classList.remove('active');
  };

  animateIn = () => {
    this.doms.listHovers[this.activeIndex].classList.add('active');
  };

  animateOut = () => {
    this.doms.listHovers[this.activeIndex].classList.remove('active');
  };
}

const list = () => {
  const listElems = document.querySelectorAll('[data-list]');
  listElems.forEach((elem) => {
    new List(elem);
  });
};

export default list;
