const loadImage = (img) =>
  new Promise((resolve, reject) => {
    img.addEventListener('load', () => resolve(img));
  });

export const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getImageLightness = async (image, callback) => {
  var colorSum = 0;
  const loadedImage = await loadImage(image);
  // create canvas
  var canvas = document.createElement('canvas');
  canvas.width = loadedImage.width;
  canvas.height = loadedImage.height;

  var ctx = canvas.getContext('2d');
  ctx.drawImage(loadedImage, 0, 0);

  var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  var data = imageData.data;
  var r, g, b, avg;

  for (var x = 0, len = data.length; x < len; x += 4) {
    r = data[x];
    g = data[x + 1];
    b = data[x + 2];

    avg = Math.floor((r + g + b) / 3);
    colorSum += avg;
  }

  var brightness = Math.floor(
    colorSum / (loadedImage.width * loadedImage.height),
  );
  return brightness;
};

export const isTouchDevice = () => {
  return (
    'ontouchstart' in window || // html5 browsers
    navigator.maxTouchPoints > 0 || // future IE
    navigator.msMaxTouchPoints > 0
  ); // current IE10
};
