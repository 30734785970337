class Navigation {
  constructor(container) {
    this.container = container;
    this.toggler = container.querySelector('[data-navigation-toggler]');
    this.nav = container.querySelector('[data-navigation-nav]');

    this.toggler.addEventListener('click', this.handleTogglerClick);

    let scrollPos = 0;
    // adding scroll event
    window.addEventListener('scroll', () => {
      const scrollTop = document.body.getBoundingClientRect().top;
      if (
        scrollTop > scrollPos ||
        Math.abs(document.body.getBoundingClientRect().top) >=
          document.body.scrollHeight - window.innerHeight
      ) {
        document.body.classList.remove('is-scrolled-down');
      } else if (scrollPos < 0) {
        document.body.classList.add('is-scrolled-down');
      }
      scrollPos = scrollTop;
    });
  }

  handleTogglerClick = () => {
    this.container.classList.toggle('navigation--active');
    this.nav.classList.toggle('navigation__nav--active');
  };
}

const navigation = () => {
  const navigationElem = document.querySelector('[data-navigation]');
  if (navigationElem) {
    new Navigation(navigationElem);
  }
};

export default navigation;
