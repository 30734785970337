class Grid {
  constructor(container) {
    this.container = container;
    this.tiles = container.querySelectorAll('[data-grid-tile]');
    this.init();
  }

  init = () => {
    this.initTiles();
    window.addEventListener('resize', () => {
      this.initTiles();
    });
  };

  initTiles = () => {
    this.tiles.forEach((tile) => {
      const content = tile.querySelector('[data-grid-tile-content]');
      if (content) {
        content.removeEventListener('scroll', this.handleTileScroll);

        if (content.scrollHeight - content.clientHeight > 0) {
          tile.classList.add('is-scrollable');
          content.addEventListener('scroll', this.handleTileScroll);
        } else {
          tile.classList.remove('is-scrollable');
        }
      }
    });
  };

  handleTileScroll = (e) => {
    const elem = e.target;
    const parent = elem.parentElement;
    if (elem.scrollTop > 0) {
      parent.classList.add('is-scrolled');
    } else {
      parent.classList.remove('is-scrolled');
    }
  };
}

const grid = () => {
  const gridElems = document.querySelectorAll('[data-grid]');
  gridElems.forEach((elem) => {
    new Grid(elem);
  });
};

export default grid;
