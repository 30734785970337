import 'regenerator-runtime/runtime.js';
import {tns} from 'tiny-slider/src/tiny-slider';
import accordion from './accordion';
import homeCarousel from './home-carousel';
import glitch from './glitch';
import grid from './grid';
import lazyload from './lazyload';
import line from './line/index';
import list from './list';
import navigation from './navigation';
import paragraphText from './paragraph-text';

const galleryElems = document.querySelectorAll('[data-gallery]');
galleryElems.forEach((galleryElem) => {
  const container = galleryElem.querySelector('[data-gallery-slider]');
  const controls = galleryElem.querySelector('[data-gallery-controls]');

  new tns({
    container: container,
    controlsContainer: controls,
    items: 1,
    autoplay: true,
    autoplayHoverPause: true,
    autoplayButton: false,
    autoplayTimeout: 3000,
    autoplayButtonOutput: false,
    speed: 1000,
    nav: false,
    lazyload: true,
    responsive: {
      768: {
        items: 3,
      },
      1600: {
        items: 4,
      },
    },
  });
});

const newsCarouselElems = document.querySelectorAll('[data-news-carousel]');
newsCarouselElems.forEach((galleryElem) => {
  const container = galleryElem.querySelector('[data-news-carousel-slider]');

  new tns({
    container: container,
    controls: false,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 5000,
    autoplayResetOnVisibility: true,
    items: 1,
    mouseDrag: true,
    nav: false,
    edgePadding: 50,
    swipeAngle: false,
    speed: 1000,
    gutter: 20,
    loop: true,
    responsive: {
      769: {
        items: 2,
        edgePadding: 100,
        gutter: 60,
      },
      1025: {
        items: 3,
        edgePadding: 200,
        gutter: 60,
      },
    },
  });
});

lazyload();

window.addEventListener('load', () => {
  accordion();
  glitch();
  grid();
  homeCarousel();
  list();
  line();
  navigation();
  paragraphText();
});
