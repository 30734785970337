const accordions = [];

const Strings = {
  SELECTOR_CONTAINER: '[data-accordion]',
  SELECTOR_BUTTON: '[data-accordion-button]',
  SELECTOR_CONTENT: '[data-accordion-content]',
};

/**
 * close other open accordions if necessary
 * @param {Element} openAccordion
 * @return {Boolean}
 */
const closeOtherAccordions = (openAccordion) => {
  let needToCloseOther = false;
  for (let i = 0; i < accordions.length; i++) {
    if (accordions[i] !== openAccordion) {
      if (accordions[i].isExpanded) {
        needToCloseOther = true;
      }

      accordions[i].closeContent();
    }
  }

  return needToCloseOther;
};

/**
 * Interactions tied to Accordion element
 */
class Accordion {
  /**
   * @param  {Element} elem
   */
  constructor(elem) {
    this.elem_ = elem;
    this.timer_ = null;
    this.isExpanded = false;
    this.elem_.classList.remove('is-expanded');
    this.init();
  }

  /**
   * Init the element
   */
  init = () => {
    this.initDoms();
    this.dom_.button.addEventListener('click', this.toggle);
    this.dom_.content.addEventListener(
      'transitionend',
      this.handleTransitionEnd,
    );

    if (
      this.elem_.dataset.accordionId &&
      this.elem_.dataset.accordionId === window.location.hash
    ) {
      this.toggle();
    }
  };

  /**
   * Find all related DOM element
   */
  initDoms = () => {
    this.dom_ = {
      button: this.elem_.querySelector(Strings.SELECTOR_BUTTON),
      content: this.elem_.querySelector(Strings.SELECTOR_CONTENT),
    };
  };

  /**
   * expand the content block
   */
  openContent = () => {
    let needToCloseOther = false;

    const timing = needToCloseOther ? 510 : 0;

    this.openTimeout = setTimeout(() => {
      this.isExpanded = true;
      this.dom_.button.setAttribute('aria-expanded', 'true');
      this.dom_.content.setAttribute('aria-hidden', 'false');
      this.elem_.classList.add('is-expanded');

      this.dom_.content.style.maxHeight = '0';

      setTimeout(() => {
        this.dom_.content.style.maxHeight = `${this.dom_.content.scrollHeight}px`;
      }, 10);
    }, timing);
  };

  /**
   * close the content block
   */
  closeContent = () => {
    this.isExpanded = false;
    this.dom_.button.setAttribute('aria-expanded', 'false');
    this.dom_.content.setAttribute('aria-hidden', 'true');
    this.dom_.content.style.maxHeight = `${this.dom_.content.scrollHeight}px`;

    this.closeTimeout = setTimeout(() => {
      this.elem_.classList.remove('is-expanded');
    }, 50);
  };

  /**
   * event listener for transition end
   * @param  {EventTarget} event
   */
  handleTransitionEnd = (event) => {
    if (event.propertyName === 'max-height') {
      this.dom_.content.style.maxHeight = '';
    }
  };

  /**
   * toggle the expand state
   */
  toggle = () => {
    clearTimeout(this.closeTimeout);

    if (this.isExpanded) {
      this.closeContent();
    } else {
      closeOtherAccordions(this);
      this.openContent();
      document.activeElement.blur();
    }
  };
}

const accordion = () => {
  [...document.querySelectorAll(Strings.SELECTOR_CONTAINER)].forEach((item) => {
    accordions.push(new Accordion(item));
  });
};

export default accordion;
