const lazyload = () => {
  document.addEventListener('DOMContentLoaded', function () {
    var lazyloadImages;

    lazyloadImages = document.querySelectorAll('[data-src]:not(.tns-lazy-img)');
    lazyloadImages.forEach((image) => {
      image.classList.add('lazyload');
    });
    var imageObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          var image = entry.target;

          image.addEventListener(
            image.tagName === 'VIDEO' ? 'loadstart' : 'load',
            () => {
              image.classList.add('loaded');
            },
          );

          if (image.dataset.src) {
          image.src = image.dataset.src;
          }
          imageObserver.unobserve(image);
        }
      });
    });

    lazyloadImages.forEach(function (image) {
      imageObserver.observe(image);
    });
  });
};

export default lazyload;
