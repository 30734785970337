import AnimationFrameManager from '../utils/AnimationFrameManager';
import CONFIG from './config';
import {point, mid, PY} from './utils';
import {getRandomInt, isTouchDevice} from '../utils';

class Line {
  constructor(container) {
    this.container = container;
    this.isVertical =
      this.container.getAttribute('data-interactive-line-vertical') !== null;
    this.canvasWidth = this.isVertical
      ? CONFIG.canvasHeight
      : this.container.clientWidth;
    this.canvasHeight = this.isVertical
      ? this.container.clientHeight
      : CONFIG.canvasHeight;
    this.isDarkLine =
      container.getAttribute('data-interactive-line-white') === null;
    this.init();
  }

  init = () => {
    this.initLine();
    this.createCanvas();
    this.observer_ = new IntersectionObserver(this.handleObserve, {
      rootMargin: '0% 0% 0% 0%',
      threshold: 0,
    });

    this.observer_.observe(this.container);

    window.addEventListener('resize', this.handleWindowResize);
    this.container.classList.add('loaded');
  };

  initLine = () => {
    this.line = [];
    const middle = this.isVertical
      ? this.canvasWidth / 2
      : this.canvasHeight / 2; //middle

    const points = Math.floor(
      (this.isVertical ? this.canvasHeight : this.canvasWidth) / CONFIG.points,
    );
    for (let i = points; i >= 0; i--) {
      let coord = Math.round(
        ((this.isVertical ? this.canvasHeight : this.canvasWidth) / points) * i,
      );
      this.line.push(
        this.isVertical ? point(middle, coord) : point(coord, middle),
      );
    }
  };

  createCanvas = () => {
    this.canvas = document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d');

    this.setCanvasSize();

    this.container.appendChild(this.canvas);

    // Events

    this.mouse = {
      x: -500,
      y: this.canvas.height / 2,
    };
  };

  /**
   * Toggle animation frame according to whether in viewport
   * @param  {Array} entries
   */
  handleObserve = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        AnimationFrameManager.addSubscriber(this.draw);
      } else {
        AnimationFrameManager.removeSubscriber(this.draw);
      }
    });
  };

  draw = () => {
    this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    let line, xc, yc, cur, curX, curY, next, dot, i;
    // Update before rendering
    if (!isTouchDevice()) {
      this.updateLine();
    }
    line = this.line;
    this.ctx.beginPath();
    this.ctx.strokeStyle = this.isDarkLine ? '#000000' : '#ffffff';
    this.ctx.moveTo(line[line.length - 1].x, line[line.length - 1].y);
    for (i = line.length - 2; i > 1; i--) {
      cur = line[i];
      curX = cur.x;
      curY = cur.y;
      next = line[i - 1];
      xc = (curX + next.x) / 2;
      yc = (curY + next.y) / 2;

      this.ctx.quadraticCurveTo(curX, curY, xc, yc);
    }

    this.ctx.quadraticCurveTo(
      line[i].x,
      line[i].y,
      line[i - 1].x,
      line[i - 1].y,
    );
    this.ctx.stroke();
  };

  updateLine = () => {
    let point,
      desiredX,
      desiredY,
      desiredH,
      desiredForce,
      desiredAngle,
      hvx,
      hvy,
      mvx,
      mvy,
      x,
      y,
      homeX,
      homeY,
      vx,
      vy;
    let radius = CONFIG.radius;
    let maxSpeed = CONFIG.maxSpeed;

    if (window.mouseX && window.mouseY) {
      var rect = this.canvas.getBoundingClientRect();
      this.mouse.x = window.mouseX - rect.left;
      this.mouse.y = window.mouseY - rect.top;
    }

    for (var j = this.line.length - 1; j >= 0; j--) {
      point = this.line[j];
      x = point.x;
      y = point.y;
      (hvx = 0), (hvy = 0);

      // Home forces
      homeX = point.hx;
      homeY = point.hy;
      if (x !== homeX || y !== homeY) {
        desiredX = homeX - x;
        desiredY = homeY - y;
        desiredH = PY(desiredX, desiredY);
        desiredForce = Math.max(desiredH * 0.2, 1);
        desiredAngle = Math.atan2(desiredY, desiredX);
        hvx = desiredForce * Math.cos(desiredAngle);
        hvy = desiredForce * Math.sin(desiredAngle);
      }
      // Mouse Forces
      (mvx = 0), (mvy = 0);
      desiredX = x - this.mouse.x;
      desiredY = y - this.mouse.y;
      if (
        !(
          desiredX > radius ||
          desiredY > radius ||
          desiredY < -radius ||
          desiredX < -radius
        )
      ) {
        desiredAngle = Math.atan2(desiredY, desiredX);
        desiredH = PY(desiredX, desiredY);
        desiredForce = Math.max(0, Math.min(radius - desiredH, radius));
        mvx = desiredForce * Math.cos(desiredAngle);
        mvy = desiredForce * Math.sin(desiredAngle);
      }
      // Combine and limit
      vx = Math.round(mid((mvx + hvx) * 0.9, maxSpeed, -maxSpeed));
      vy = Math.round(mid((mvy + hvy) * 0.9, maxSpeed, -maxSpeed));
      // Dont let point get too far from home
      if (vx != 0) {
        point.x += vx;
      }
      if (vy != 0) {
        point.y += vy;
      }
      this.line[j] = point;
    }
  };

  handleWindowResize = () => {
    this.canvasWidth = this.isVertical
      ? CONFIG.canvasHeight
      : this.container.clientWidth;
    this.canvasHeight = this.isVertical
      ? this.container.clientHeight
      : CONFIG.canvasHeight;
    this.initLine();
    this.setCanvasSize();
  };

  setCanvasSize = () => {
    const dpr = window.devicePixelRatio || 1;

    this.canvas.width = this.canvasWidth * dpr;
    this.canvas.height = this.canvasHeight * dpr;
    this.canvas.style.width = this.canvasWidth + 'px';
    this.canvas.style.height = this.canvasHeight + 'px';

    this.ctx.scale(dpr, dpr);
  };
}

const line = () => {
  window.addEventListener('mousemove', (e) => {
    window.mouseX = e.clientX;
    window.mouseY = e.clientY;
  });
  const lineElems = document.querySelectorAll('[data-interactive-line]');
  lineElems.forEach((elem) => {
    new Line(elem);
  });
};

export default line;
