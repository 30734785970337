export const point = (x, y) => {
  return {
    x: x,
    y: y,
    hx: x,
    hy: y,
  };
};
// Helper Functions
// Get number in the middle
export function mid() {
  const args = Array.from(arguments);
  if (args.length < 3) return args[0] || 0;
  const sorted = args.slice().sort((a, b) => a - b);
  return sorted[Math.round((sorted.length - 1) / 2)];
}
// Phytagoshananigans theory
export const PY = (x, y) =>
  Math.sqrt(Math.pow(Math.abs(x), 2) + Math.pow(Math.abs(y), 2), 2);
