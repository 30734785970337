/**
 * A manager to organize all animationFrame requests and call all callbacks in sync
 */
class AnimationFrameManager {
  /**
   * Make a singleton class
   * @return {AnimationFrameManagerObject}
   */
  constructor() {
    if (!AnimationFrameManager.instance) {
      AnimationFrameManager.instance = this;
      this._observers = [];
    }

    return AnimationFrameManager.instance;
  }

  /**
   * Add a subscriber
   * @param {Function} observer
   */
  addSubscriber = (observer) => {
    if (this._observers.length === 0) {
      this.onFrame();
    }
    this._observers.push(observer);
  };

  /*
   * Remove a subscriber
   * @param {Function} observer
   */
  removeSubscriber = (observer) => {
    this._observers = this._observers.filter((item) => item !== observer);

    if (this._observers.length === 0) {
      cancelAnimationFrame(this.frameRequest);
    }
  };

  /*
   * Callback function on each frame
   */
  onFrame = (now) => {
    this._observers.forEach((observer) => observer(now));
    this.frameRequest = requestAnimationFrame(this.onFrame);
  };
}

export default new AnimationFrameManager();
