const ANIMATION_DURATION = 1200;
const AUTOSLIDE_DURATION = 5500;

class HomeCarousel {
  constructor(container) {
    this.container = container;
    this.slidesCount = parseInt(
      container.getAttribute('data-home-slider-slides-count'),
      10,
    );
    this.currentIndex = this.slidesCount - 1;
    this.init();
  }

  init = () => {
    this.initDoms();
    window.addEventListener('resize', this.resize);
    this.resize();
    this.container.classList.add('loaded');

    this.doms.prevButton.addEventListener('click', this.handlePrevClick);
    this.doms.nextButton.addEventListener('click', this.handleNextClick);

    this.autoSlide();
  };

  autoSlide = () => {
    this.previousIndex = this.currentIndex;
    this.currentIndex = (this.currentIndex + 1) % this.slidesCount;
    this.slide();

    this.autoslideTimeout = setTimeout(() => {
      this.autoSlide();
    }, AUTOSLIDE_DURATION);
  };

  resize = () => {
    this.setTitleSize();
  };

  setTitleSize = () => {
    let height = 0;
    this.doms.titles.forEach((title) => {
      const titleHeight = title.clientHeight;
      height = Math.max(titleHeight, height);
    });
    this.doms.titleWrapper.style.height = `${height}px`;
  };

  initDoms = () => {
    this.doms = {
      prevButton: this.container.querySelector(
        '[data-home-carousel-prev-button]',
      ),
      nextButton: this.container.querySelector(
        '[data-home-carousel-next-button]',
      ),
      backgroundImages: this.container.querySelectorAll(
        '[data-home-carousel-background-image] > div',
      ),
      titleWrapper: this.container.querySelector(
        '[data-home-carousel-background-title-wrapper]',
      ),
      titles: this.container.querySelectorAll(
        '[data-home-carousel-background-title]',
      ),
      illustrations: this.container.querySelectorAll(
        '[data-home-carousel-illustrations]',
      ),
      linkList: this.container.querySelectorAll(
        '[links-home-carousel]'
      ),
      linkElements: this.container.querySelectorAll(
        '[data-home-carousel-link]'
      ),
      links: this.container.querySelectorAll(
        '[data-home-carousel-link-label]'
      ),
      eyebrows: this.container.querySelectorAll(
        '[data-home-carousel-eyebrow]'
      )
    };
  };

  handlePrevClick = () => {
    clearTimeout(this.autoslideTimeout);
    this.previousIndex = this.currentIndex;
    this.currentIndex =
      this.currentIndex > 0
        ? (this.currentIndex - 1) % this.slidesCount
        : this.slidesCount - 1;

    this.slide();
    this.autoslideTimeout = setTimeout(() => {
      this.autoSlide();
    }, AUTOSLIDE_DURATION);
  };

  handleNextClick = () => {
    clearTimeout(this.autoslideTimeout);
    this.previousIndex = this.currentIndex;
    this.currentIndex = (this.currentIndex + 1) % this.slidesCount;
    this.slide();
    this.autoslideTimeout = setTimeout(() => {
      this.autoSlide();
    }, AUTOSLIDE_DURATION);
  };

  slide = () => {
    this.animateOut(this.previousIndex);
    setTimeout(() => {for(let link of this.doms.linkElements) {
      link.setAttribute('href',this.doms.linkList[this.currentIndex].innerHTML)
    }}, ANIMATION_DURATION/2)
    this.animateIn(this.currentIndex);
  };

  animateOut = (index) => {
    this.doms.backgroundImages[index].classList.add('animate-out');
    this.doms.titles[index].classList.add('animate-out');
    this.doms.illustrations[index].classList.add('animate-out');
    this.doms.links[index].classList.add('animate-out');
    this.doms.eyebrows[index].classList.add('animate-out');

    this.doms.backgroundImages[index].classList.remove('active');
    this.doms.titles[index].classList.remove('active');
    this.doms.illustrations[index].classList.remove('active');
    this.doms.links[index].classList.remove('active');
    this.doms.eyebrows[index].classList.remove('active');

    setTimeout(() => {
      this.doms.backgroundImages[index].classList.remove('animate-out');
      this.doms.titles[index].classList.remove('animate-out');
      this.doms.illustrations[index].classList.remove('animate-out');
      this.doms.links[index].classList.remove('animate-out');
      this.doms.eyebrows[index].classList.remove('animate-out');
    }, ANIMATION_DURATION);
  };

  animateIn = (index) => {
    this.doms.backgroundImages[index].classList.add('active');
    this.doms.titles[index].classList.add('active');
    this.doms.illustrations[index].classList.add('active');
    this.doms.links[index].classList.add('active');
    this.doms.eyebrows[index].classList.add('active');

  };
}

const homeCarousel = () => {
  const homeCarouselElem = document.querySelector('[data-home-carousel]');
  if (homeCarouselElem) {
    new HomeCarousel(homeCarouselElem);
  }
};

export default homeCarousel;
