import {getRandomInt} from '../utils';
const SHAPES = ['T', 'L', 'rectangle', 'triangle', 'rectangle'];

class GlitchCanvas {
  constructor(wrapper, width, height, isDark) {
    this.wrapper = wrapper;
    this.canvasWidth = width;
    this.canvasHeight = height;
    this.isDark = isDark;
    this.init();
  }

  init = () => {
    this.canvas = this.createCanvas();
    this.ctx = this.canvas.getContext('2d');
    this.i = 0;
    this.glitches = [];
    this.mouseX = -1;
    this.mouseY = -1;
    this.mouseSpeed = 0;
  };

  createCanvas = () => {
    const canvas = document.createElement('canvas');
    canvas.width = this.canvasWidth;
    canvas.height = this.canvasHeight;
    this.wrapper.appendChild(canvas);

    return canvas;
  };

  draw = () => {
    //create glitches
    if (this.i % 5 === 0 && this.active) {
      this.createGlitches();
    }

    // draw
    this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    this.glitches.forEach((glitch, index, object) => {
      const opacity = this.getOpacity(glitch.opacity);
      this.ctx.fillStyle = `rgba(255, 255, 255, ${opacity})`;
      glitch.points.forEach((point, index) => {
        if (index === 0) {
          this.ctx.beginPath();
          this.ctx.moveTo(point.x, point.y);
        } else {
          this.ctx.lineTo(point.x, point.y);
        }

        if (index === glitch.points.length - 1) {
          this.ctx.closePath();
        }
      });
      this.ctx.fill();
      this.updateGlitchOpacity(glitch);
    });
    this.glitches = this.glitches.filter((glitch) => glitch.opacity > 0);

    // raf
    if (!this.active && this.glitches.length === 0) {
      cancelAnimationFrame(this.raf);
    } else {
      this.raf = requestAnimationFrame(this.draw);
    }
    this.i++;
  };

  createGlitches = () => {
    const glitchesCount = Math.floor(Math.min(2, Math.max(0, this.mouseSpeed)));

    for (let index = 0; index < glitchesCount; index++) {
      const x = getRandomInt(this.mouseX - 80, this.mouseX + 80);
      const y = getRandomInt(this.mouseY - 40, this.mouseY + 40);
      const points = this.getPoints(x, y);
      this.glitches.push({x, y, points, opacity: 1});
    }
  };

  getPoints = (x, y) => {
    const points = [];
    const shape = SHAPES[getRandomInt(0, SHAPES.length - 1)];
    const w = getRandomInt(5, 40);
    const h = getRandomInt(5, 40);
    const thickness = getRandomInt(5, 10);

    switch (shape) {
      case 'T':
        points.push(
          {x: x - w / 2, y: y - h / 2},
          {x: x + w / 2, y: y - h / 2},
          {x: x + w / 2, y: y - h / 2 + thickness},
          {x: x + thickness / 1, y: y - h / 2 + thickness},
          {x: x + thickness / 1, y: y + h / 2},
          {x: x - thickness / 1, y: y + h / 2},
          {x: x - thickness / 1, y: y - h / 2 + thickness},
          {x: x - w / 2, y: y - h / 2 + thickness},
        );
        break;
      case 'L':
        points.push(
          {x: x + w / 2, y: y + h / 2},
          {x: x - w / 2, y: y + h / 2},
          {x: x - w / 2, y: y - h / 2},
          {x: x - w / 2 + thickness, y: y - h / 2},
          {x: x - w / 2 + thickness, y: y + h / 2 - thickness},
          {x: x + w / 2, y: y + h / 2 - thickness},
        );
        break;
      case 'rectangle':
        points.push(
          {x: x - w / 2, y: y - h / 2},
          {x: x + w / 2, y: y - h / 2},
          {x: x + w / 2, y: y + h / 2},
          {x: x - w / 2, y: y + h / 2},
        );
        break;
      case 'triangle':
        points.push(
          {x, y: y - h / 2},
          {x: x + w / 2, y: y + h},
          {x: x - w / 2, y: y + h},
        );
        break;
      default:
        break;
    }
    return points;
  };

  startAnimation = () => {
    this.active = true;
    this.draw();
  };

  stopAnimation = () => {
    this.active = false;
  };

  updateMousePosition = (mouseX, mouseY, mouseSpeed) => {
    this.mouseX = mouseX;
    this.mouseY = mouseY;
    this.mouseSpeed = mouseSpeed;
  };

  updateGlitchOpacity(glitch) {
    const decrementStrength = 0.02;

    if (glitch.opacity > 0) {
      glitch.opacity -= decrementStrength;
    }
  }

  getOpacity = (opacity) => {
    const t = 1 - opacity;
    return 1 - t * t * t * t * t * t;
  };
}

export default GlitchCanvas;
