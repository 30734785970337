import GlitchCanvas from './canvas';
import {getImageLightness} from '../utils';

class Glitch {
  constructor(container) {
    this.container = container;
    this.glitchElem = container.querySelector('[data-glitch]');
    this.image = container.querySelector('[data-glitch-image]');
    this.init();
  }

  init = () => {
    this.isDark = false;
    this.canvas = new GlitchCanvas(
      this.glitchElem,
      this.container.clientWidth,
      this.container.clientHeight,
      this.isDark,
    );
    const glitchRect = this.glitchElem.getBoundingClientRect();
    this.currentX = glitchRect.left;
    this.currentY = glitchRect.top;
    this.mouseX = 0;
    this.mouseY = 0;
    this.width = this.glitchElem.clientWidth || glitchRect.width;
    this.height = this.glitchElem.clientHeight || glitchRect.height;

    this.container.addEventListener('mousemove', this.handleMouseMove);
    this.container.addEventListener('mouseenter', this.showGlitch);
    this.container.addEventListener('mouseleave', this.hideGlitch);
  };

  handleMouseMove = (e) => {
    this.mouseX = e.clientX - this.container.getBoundingClientRect().left;
    this.mouseY = e.clientY - this.container.getBoundingClientRect().top;
    this.mouseSpeed = (Math.abs(e.movementX) + Math.abs(e.movementY)) / 2;
    this.canvas.updateMousePosition(this.mouseX, this.mouseY, this.mouseSpeed);

    if (this.stopMouseSpeed) {
      clearTimeout(this.stopMouseSpeed);
    }
    this.stopMouseSpeed = setTimeout(() => {
      this.canvas.updateMousePosition(this.mouseX, this.mouseY, 0);
    }, 800);

    if (this.glitchActive) {
      this.canvas.startAnimation();
      this.glitchActive = false;
    }
  };

  showGlitch = (e) => {
    this.glitchActive = true;
  };

  hideGlitch = () => {
    this.canvas.stopAnimation();
  };
}

const glitch = () => {
  const glitchElems = document.querySelectorAll('[data-glitch-container]');
  glitchElems.forEach((elem) => {
    new Glitch(elem);
  });
};

export default glitch;
